import React from "react"
import { graphql } from 'gatsby'
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Blog from "../../components/body/pages/blog"
const BlogPage = ({ data }) => {
  const formatedData = {
    categories: data?.allStrapiCategory?.nodes,
    blogs: data?.allStrapiBlog?.nodes
  }
  return (
    <Layout>
      <SEO
        canonical={"https://kuda.com/blog/"}
        title="Kuda's Blog | Follow Kuda's thoughts, get updates, news and dive into helpful information | Kuda"
        description="Recent news, Product Updates, Marketing Insights and Company Information from Kuda"
      />
      <Blog {...formatedData}/>
    </Layout>
  )
}

export default BlogPage


export const query = graphql`
  query {
    allStrapiBlog(sort: {fields: publishedDate, order: DESC}) {
    nodes {
        blogHeroImage {
          url
        }
        isTestBlog
        blogSubtitle
        blogTitle
        publication
        seoTitle
        seoDescription
        publishedDate
        publishedAt
        publication
        categories {
          categoryKey
        }
      }
    }
    allStrapiCategory {
      nodes {
        categoryKey
        categoryName
        displayName
      }
    }
  }
`