import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import moment from "moment"
import { formatPathString } from "../../../../../util/util"
import { Img } from "react-image"
import Skeleton from "react-loading-skeleton";
export function BlogListCards(props) {
  const { blogs } = props
  return (
    <BlogListStyled className="kuda-section--100 kuda-spacing--inner kuda-disclaimer--section container">
      <div className="kuda-press--cards flex justify-between flex-wrap">
        {blogs?.map((item, i) => {
          const formatedPath = formatPathString(item?.blogTitle)
          const fetchpriority = i > 2 ? "low" : "high"
          return (
            <Link
              to={`/blog/${formatedPath}/`}
              rel="noopener noreferrer"
              key={i}
              className="kuda-single--press"
            >
              <div className="kuda-blog-card-image">
                <Img
                  src={`${process.env.GATSBY_BLOGCMS_URL}${item?.blogHeroImage?.url}`}
                  alt="kuda blog card"
                  loader={<Skeleton width={'100%'} height={'100%'} />}
                  height={"100%"}
                  width={"100%"}
                  fetchpriority={fetchpriority}
                />
              </div>
              <div className="kuda-press--wrap">
                <div className="kuda-press--info flex justify-between align-center">
                  <div className="kuda-press--publication">
                    {item.publication}
                  </div>
                  <div className="kuda-press--date">
                    {moment(item?.publishedDate).format("DD MMM yyyy")}
                  </div>
                </div>
                <div className="kuda-press--details">
                  <h2
                    className={`text-xlbold ${props.headingColor ?? "color-primary"
                      } title-bottom--spacing kuda-press--heading`}
                  >
                    {item?.blogTitle}
                  </h2>
                  <p className="color-black kuda-press--subheading">
                    {item?.blogSubtitle}
                  </p>
                </div>
              </div>
            </Link>
          )
        })}
      </div>
    </BlogListStyled>
  )
}
export const BlogListStyled = styled.div`
  .kuda-single--press {
    width: 100%;
    @media only screen and (min-width: 767px) {
      width: calc(50% - 20px);
    }
  }
  .kuda-blog-card-image {
    height: 220px;
  }

  .kuda-blog-card-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }

  @media only screen and (max-width: 767px) {
    
  .kuda-blog-card-image {
      height: 170px;
    }
  }
  @media only screen and (min-width: 767px) and (max-width: 960px) {
     .kuda-single--press {
      width: 100%;
      max-width: calc(70% - 20px);
      margin: 0 auto;
      margin-bottom: 40px;
    }
     
  }
`